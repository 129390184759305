// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAgq_F9r02h3-zDTrs9qQft84bhWY8XC44",
  authDomain: "gasbilligl.firebaseapp.com",
  databaseURL: "https://gasbilligl-default-rtdb.firebaseio.com",
  projectId: "gasbilligl",
  storageBucket: "gasbilligl.appspot.com",
  messagingSenderId: "840603614127",
  appId: "1:840603614127:web:6d11ee39dadf5410b7cd5a",
  measurementId: "G-8HQTD3L57G"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };